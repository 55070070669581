/* eslint-disable react/no-danger */
import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import { Accordion, AccordionTab } from '@lmig/lmds-react';
import Image from '@lmig/dotcom-aspect-components/Utility/Image';
import css from "styled-jsx/css";
import RatingStars from './RatingStars';

const { className: accordionClass, styles: accordionStyles } = css.resolve`
  .lmig-Accordion{
    margin: 1rem 0 1.5rem 0;
  }
  :global(.accordionCustom .lmig-Accordion-list-listItem:last-child ) {
    border-bottom: 0;
  }

  :global(.accordionCustom .lmig-Accordion-list-listItem-header){
    background-color: #f5f5f5;
    color: #343741;
    font-weight: 500;
    font-size: 1rem;
  }
`;

const Review = ({
  review
}) => {
  const ifNoRatingUseThis = 5;
  const rating = review.rating || ifNoRatingUseThis;

  return (
    <div id="review">
      <div className="reviewInfoWrapper">
        <Image imageDomain="IMAGE">
          <img
            className="logoImg"
            src={review?.publisherId === "GOOGLEMYBUSINESS" ? "/comparion/google-logo.svg" : "/comparion/comparion-logo.svg"}
            alt={review?.publisherId === "GOOGLEMYBUSINESS" ? "Google logo" : "Comparion logo"}
            width="50"
            height="50"
          />
        </Image>
        <div className="author-date">
          <div className="author"><span>{review.authorName || 'Anonymous'}</span></div>
          <div className="publisherDate">{new Date(review.publisherDate).toLocaleDateString('en-US', { dateStyle: 'long' })}</div>
          <div className="ratingStarWrapper">
            <div className="ratingStars">
              <RatingStars rating={rating} size={16} iconColor="#ffd000" starType="lmds" />
            </div>
            <div className="rating">
              {`${rating} / 5 stars`}
            </div>
          </div>
        </div>

      </div>
      <div className="content">
        {!!review.content && <div>{review.content}</div>}
      </div>
      {review.comments?.length > 0 &&
        <Accordion noBorderTop className={`${accordionClass} accordionCustom`} data-testid={`${review.authorName}-comment`}>
          <AccordionTab labelVisual="Response from Comparion Insurance">
            {review.comments.map(comment => (
              <Fragment key={comment.content}>
                <div className="commentDate">{new Date(comment.publisherDate).toLocaleDateString('en-US', { dateStyle: 'long' })}</div>
                <div className="comment">{comment.content}</div>
              </Fragment>
            ))}
          </AccordionTab>
        </Accordion>}
      {accordionStyles}
      <style global jsx>{`
        #review{
          margin-top: 1rem;
          &:not(:last-child) {
            border-bottom: 1px solid #C0BFC0;
          }
          @media screen and (min-width: 75rem) {
            flex-direction: row;
          }

          .reviewInfoWrapper{
            display: flex;
            align-items: flex-start;
          }

          .logoImg{
            border: 4px solid #E6E6E6;
            padding: 0.35rem;
            border-radius: 50%;
            margin-right: 0.5rem;
          }

          .author-date {
            width: 100%;
            @media screen and (min-width: 75rem) {
              max-width: 30%;
            }
          }
          .author {
            font-weight: 700;
          }
          .content {
            margin-left: unset;
            @media screen and (min-width: 41rem) {
              margin-left: 3.5rem;
            }
            .title {
              font-weight: 700;
              margin-top: 2rem;
            }
            .commentDate {
              margin-bottom: 1rem;
            }
          }
          .rating{
            margin: 0 0 0 0.5rem;
            @media screen and (min-width: 41rem) {
              margin: 0 0 1rem 0.5rem;
            }
          }
          .ratingStars{
            margin-top: 0.15rem;
            height: 1.2rem;
          }
          .ratingStarWrapper{
            display: flex;
          }
        }
      `}</style>
    </div >
  );
};

Review.propTypes = {
  review: PropTypes.shape({
    rating: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
    authorName: PropTypes.string.isRequired,
    publisherDate: PropTypes.number.isRequired,
    publisherId: PropTypes.string,
    comments: PropTypes.arrayOf(PropTypes.shape({
      publisherDate: PropTypes.number,
      authorName: PropTypes.string,
      authorRole: PropTypes.string,
      authorEmail: PropTypes.string,
      content: PropTypes.string,
      visibility: PropTypes.string
    })),
  }).isRequired
};

export default Review;
