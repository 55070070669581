import React from "react";
import PropTypes from "prop-types";

const Dot = ({
  color = '#1a1446',
  number = 3,
  fill = true,
  fontSize = 1,
  margin = '0 0.25rem',
  inline = false,
  inlineMargin = '0',
  alignMobile,
  alignDesktop
}) => (
  <>
    <div className="dotWrapper">
      {Array.from(Array(number).keys()).map((v) => (
        <span key={v} className="dot" />
      ))}
    </div>
    <style jsx>{`
        @import './global-styles/_tokens.scss';

        .dotWrapper {
          font-size: ${fontSize}rem;
          display: ${inline ? 'inline-block' : 'block'};
          margin: ${inlineMargin};
          text-align: ${alignMobile};

          @media screen and (min-width: $lm-breakpoint-lg-min) {
            text-align: ${alignDesktop};
          }
        }
        .dot {
          display: inline-block;
          width: 0.3536em;
          height: 0.3536em;
          transform: rotate(45deg);
          border: 1px solid ${color};
          background: ${fill ? `${color}` : `transparent`};
          margin: ${margin};
        }
      `}</style>
  </>
);

Dot.propTypes = {
  color: PropTypes.string,
  number: PropTypes.number,
  fill: PropTypes.bool,
  inline: PropTypes.bool,
  inlineMargin: PropTypes.string,
  fontSize: PropTypes.number,
  margin: PropTypes.string,
  alignMobile: PropTypes.string,
  alignDesktop: PropTypes.string,
};

export default Dot;
