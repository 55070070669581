import React from 'react';
import PropTypes from 'prop-types';

const ReviewsSchema = ({ reviews = [], totalRating = undefined }) =>
(<>
  <span itemScope="itemScope" itemProp="aggregateRating" itemType="http://schema.org/AggregateRating">
    <meta itemProp="ratingValue" content={totalRating} />
    <meta itemProp="reviewCount" content={reviews?.length} />
    <meta itemProp="bestRating" content="5" />
    <meta itemProp="worstRating" content="0" />
  </span>
  {reviews?.length > 0 && reviews?.map((review) => {
    const ifNoRatingUseThis = 5;
    const rating = review.rating || ifNoRatingUseThis;

    return (
      <div itemProp="review" itemScope="itemScope" itemType="https://schema.org/UserReview" key={review.authorName}>
        <div itemProp="author" itemScope="itemscope" itemType="https://schema.org/Person">
          <meta itemProp="name" content={review.authorName || 'Anonymous'} />
        </div>
        <meta itemProp="datePublished" content={new Date(review.publisherDate).toLocaleDateString('en-US', { dateStyle: 'long' })} />
        <div itemProp="reviewRating" itemScope="itemscope" itemType="https://schema.org/Rating">
          <meta itemProp="ratingValue" content={rating} />
        </div>
        {!!review.content && <meta itemProp="reviewBody" content={review.content} />}
      </div>
    );
  })}
</>);

ReviewsSchema.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.shape({
    rating: PropTypes.number,
    content: PropTypes.string,
    authorName: PropTypes.string,
    publisherDate: PropTypes.number,
    reviewLanguage: PropTypes.string,
    comments: PropTypes.arrayOf(PropTypes.shape({
      publisherDate: PropTypes.number,
      authorName: PropTypes.string,
      authorRole: PropTypes.string,
      authorEmail: PropTypes.string,
      content: PropTypes.string,
      visibility: PropTypes.string
    })),
  })),
  totalRating: PropTypes.string,
};

export default ReviewsSchema;